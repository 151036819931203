import React from "react";
import Footer from "../components/Footer";
import Header from "../components/Header/Header";
import Hero from "../components/Hero";
import HeroBlog from "../components/HeroBlog/HeroBlog";
import Layout from "../components/Layout";
import PostList from "../components/PostList";
import SEO from "../components/SEO";

const BlogPage = ({ data }) => {
  const {
    posts: { nodes: posts },
  } = data;
  return (
    <>
      <SEO title="Blog" />
      {posts && <PostList posts={posts} />}
      {/* PostList */}
      {/* PostItem: Image, Title, truncated text, date */}
      {/* Botón: Más `psts */}
    </>
  );
};
export const query = graphql`
  query {
    posts: allSanityBlog {
      nodes {
        title {
          es
        }
        slug {
          current
        }
        image {
          asset {
            url
            fluid(maxWidth: 400) {
              ...GatsbySanityImageFluid
            }
          }
        }
        description {
          es
        }
        date
      }
    }
  }
`;

export default BlogPage;
